import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { useAppForm } from './';

import Cross from '@svg/cross.svg';

const ErrorPopup = ({ setErrorOpen, error }) => {
  const { handleErrorReset } = useAppForm();

  const handleErrorClose = () => {
    handleErrorReset();
    setErrorOpen(false);
  };

  return (
    <AnimatePresence>
      <div className='bg-black w-full h-screen relative   text-center flex items-center overflow-hidden'>
        <div
          onClick={handleErrorClose}
          className='absolute top-5 right-1/2 translate translate-x-1/2 lg:right-20 cursor-pointer z-50  rounded-full h-16 w-16 '
        >
          <Cross className='w-[29px] lg:w-[40px] absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2' />
        </div>
        <div className='px-gutter lg:px-40 py-28  lg:py-[181px] w-[90vw] mx-auto lg:w-auto text-center  '>
          <h1 className=' text-[48px] lg:text-[80px] pb-8 font-cenzo-bold text-red uppercase'>
            Oops
          </h1>
          {error === 'weekly limit' ? (
            <p className=" text-[20px] lg:text-[40px] font-cenzo-regular uppercase'">
              I'm sorry but you have exceeded the maximum 2 entries within a
              week.
            </p>
          ) : (
            <div className='text-[20px] lg:text-[40px] font-cenzo-regular uppercase'>
              <p className=' pb-4'>
                Looks like this code is incorrect or has already been used.
              </p>
              <p className=''>Please enter another code to continue.</p>
            </div>
          )}
        </div>
      </div>
    </AnimatePresence>
  );
};

export default ErrorPopup;

import React from 'react';
import { motion } from 'framer-motion';

import Cheers from '@images/cheers.png';
import Bottle from '@images/bottle.png';

const Footer = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: 'easeInOut' }}
      className='sticky bottom-0 left-0 w-full z-30 pointer-events-none overflow-visible'
    >
      <div className='flex justify-between items-end    relative z-30 h-[40px] lg:h-[1px]'>
        <a
          href='https://cheers.org.nz/'
          target='_blank'
          rel='noreferrer'
          className='pointer-events-auto'
        >
          <img
            src={Cheers}
            alt=''
            className='max-w-[90px] lg:max-w-[123px] object-contain mb-5 ml-5 lg:mb-10 lg:ml-10   '
          />
        </a>
        <div className='pointer-events-auto'>
          <img
            src={Bottle}
            alt=''
            className='max-w-[25vh] sm:max-w-[240px]  xl:max-w-[350px] object-contain translate-x-4 lg:translate-x-0  '
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Footer;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import TermsPopup from './termsPopup';
import Confirmation from './confirmation';
import ErrorPopup from './errorPopup';
import PortalModal from '@components/UI/portalModal';

import { EntryForm } from './entryForm';
import { AgeGate } from './ageGate';
import Footer from './footer';
import CompText from './compText';
import Triangle from './triangle';
import End from './end';

const initialState = {
  isAge: false,
  isSubmitted: false,
  formValues: {},
  error: false,
};

const isClient = typeof window === 'object';

export const AppContext = createContext({
  compState: initialState,
  setAppState: () => null,
});

const FormInner = () => {
  const { isSubmitted, isAge, error } = useAppState();
  const { handleReset } = useAppForm();

  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const codeRef = useRef();

  const handleConfirmationClose = () => {
    handleReset();
    setConfirmationOpen(false);
    // clear codeRef
    codeRef.current.value = '';
  };

  useEffect(() => {
    if (isSubmitted) {
      setConfirmationOpen(true);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (error) {
      setErrorOpen(true);
    }
  }, [error]);

  //scroll to top after each modal open
  useEffect(() => {
    if (open || confirmationOpen || errorOpen) {
      window.scrollTo(0, 0);
    }
  }, [open, confirmationOpen, errorOpen]);

  return (
    <>
      <AnimatePresence mode='wait'>
        <motion.div
          initialState={{ opacity: 1 }}
          animate={{ opacity: 0 }}
          transition={{ duration: 1 }}
          className='absolute top-0 left-0 w-full h-full min-h-screen  bg-black z-[7000] pointer-events-none'
        />
        <div className='h-full  w-full  flex flex-col items-center justify-between background-image relative overflow-hidden  lg:overflow-visible '>
          <Triangle />
          <div className=' w-full relative z-50 h-screen flex flex-col justify-center items-center'>
            {/* <CompText />
            {!isAge ? (
              <AgeGate setOpen={setOpen} />
            ) : (
              <EntryForm codeRef={codeRef} setErrorOpen={setErrorOpen} />
            )} */}
            <End />
          </div>

          <Footer />
        </div>
      </AnimatePresence>
      {/* terms modal starts */}
      <PortalModal isShowing={open}>
        <TermsPopup handleClose={handleClose} />
      </PortalModal>
      {/* confirmation modal starts */}
      <PortalModal isShowing={confirmationOpen}>
        <Confirmation handleConfirmationClose={handleConfirmationClose} />
      </PortalModal>
      {/* error modal starts */}
      <PortalModal isShowing={errorOpen}>
        <ErrorPopup setErrorOpen={setErrorOpen} error={error} />
      </PortalModal>
    </>
  );
};

const CompForm = () => {
  const [compState, setCompState] = useState(initialState);
  const [isLoading, setLoading] = useState(false);

  return (
    <AppContext.Provider
      value={{ compState, setCompState, isLoading, setLoading }}
    >
      <div>
        <FormInner />
      </div>
    </AppContext.Provider>
  );
};

function useAppState() {
  const { compState } = useContext(AppContext);
  return compState;
}

function useAppLoading() {
  const { isLoading } = useContext(AppContext);
  return isLoading;
}

function useAgeGate() {
  const { setCompState } = useContext(AppContext);

  function setAge() {
    setCompState((prevState) => ({
      ...prevState,
      isAge: true,
    }));
  }

  return { setAge };
}

function useAppForm() {
  const { setCompState, compState, setLoading } = useContext(AppContext);

  // Form Change Function
  function handleChange(event) {
    event.persist();
    setCompState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        [event.target.name]: event.target.value.toUpperCase(),
      },
    }));
  }

  function handleSub(check) {
    setCompState((prevState) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        subscribe: check,
      },
    }));
  }

  async function fetchCompResult(data) {
    const response = await fetch('/.netlify/functions/handle-comp', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => res.json())
      .catch((err) => console.log(err));

    return response;
  }

  // Form Handle Submit
  async function handleSubmit(event) {
    event.preventDefault();

    // Set Loading
    setLoading(true);
    // Await response from endpoint
    const { codeInvalid, weeklyLimit } = await fetchCompResult({
      ...compState.formValues,
    });

    setLoading(false);

    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: !codeInvalid && !weeklyLimit,
      error: codeInvalid ? 'code' : weeklyLimit ? 'weekly limit' : false,
    }));
  }

  // Provide a reset
  function handleReset() {
    setCompState((prevState) => ({
      ...prevState,
      isSubmitted: false,
    }));
  }

  function handleErrorReset() {
    setCompState((prevState) => ({
      ...prevState,
      error: false,
    }));
  }

  return {
    handleChange,
    handleSub,
    handleReset,
    handleErrorReset,
    handleSubmit,
  };
}

export { CompForm, useAppState, useAppForm, useAppLoading, useAgeGate };

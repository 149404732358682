import React from "react";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { useTermsQuery } from '@querys/useTermsQuery'

import { CompForm } from "../components/compForm/";

const IndexPage = () => {
  const { seo } = useTermsQuery();
  return (
    <Layout>
      <PageMeta {...seo} />
      <CompForm />
    </Layout>
  );
};

export default IndexPage;

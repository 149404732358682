import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Cross from '@svg/cross.svg';

const TermsPopup = ({ handleClose }) => {
  const secondaryHeader =
    'font-cenzo-bold text-[18px] lg:text-[24px] mb-4 uppercase';

  return (
    <AnimatePresence>
      <div className='bg-orange my-10 w-full h-full self-center   relative  overflow-y-scroll  '>
        <div
          onClick={handleClose}
          className='sticky bg-black lg:bg-transparent top-0 right-0  cursor-pointer z-50   h-32  w-full flex  justify-center lg:justify-end  pt-10 lg:pr-10 '
        >
          <Cross className='w-[29px] lg:w-[40px] ' />
        </div>
        <div className='px-4 py-20  lg:py-[120px]  lg:px-[161px]  '>
          <h1 className='uppercase font-cenzo-bold text-[48px] lg:text-[80px] text-red text-center pb-10 lg:pb-20 leading-[1]'>
            Terms &
            <br />
            Conditions
          </h1>

          <div className='mb-10 px-4 text-[16px] lg:text-[18px]'>
            <h3 className={secondaryHeader}>
              Win a Trip to the Home of The Ned Promotion
            </h3>
            <ol className='list-decimal space-y-6 	'>
              <li>
                Information on how to enter and prizes form part of these terms
                and conditions. By participating, entrants agree to be bound by
                these terms and conditions.
              </li>
              <li>
                Entry is open to residents of New Zealand who are 18 years and
                over. Employees and their immediate families of the Promoter and
                its agencies associated with this promotion are ineligible to
                enter.
              </li>
              <li>
                The Promotion commences on 03/04/2023 and closes 23.59 on
                30/04/23 (<span className='font-cenzo-bold'>Entry Period</span>
                ). No entries will be accepted outside of the Entry Period.
              </li>
              <li>
                Winners will be determined by random draw by 05/05/23 and
                notified within five days. The first eight valid entries will
                win a Prize.
              </li>
              <li>To enter:</li>
              <ol
                type='a'
                style={{ listStyleType: 'lower-alpha' }}
                className='pl-6 space-y-2'
              >
                <li>
                  Buy any neck tagged bottle of The Ned wine during the
                  Promotion period from any participating outlet OR a glass of
                  The Ned Wine from any participating outlet and receive a
                  coaster with a unique code
                </li>
                <li>
                  Register for the promotion by filling in your details and
                  unique code online at winwiththened.co.nz
                </li>
              </ol>
              <li>
                Each unique code can be entered once. Maximum 2 entries per
                week.
              </li>
              <li>
                There are three (x3) trip packages for two people to the home of
                The Ned in Marlborough to be won (
                <span className='font-cenzo-bold'>Prize</span>). Each Prize is
                valued at $7,000 and consists of:
              </li>
              <ol
                type='a'
                style={{ listStyleType: 'lower-alpha' }}
                className='pl-6 space-y-2'
              >
                <li>
                  Return flights from the winner’s nearest commercial airport to
                  Blenheim, Marlborough.
                </li>
                <li>All Airline Taxes</li>
                <li>Rental Car</li>
                <li>
                  Two (x2) night’s accommodation at The Farmhouse in Waihopai
                  Valley
                </li>
                <li>
                  Day 1 - Winery tour and wine tasting where The Ned is crafted,
                  followed by dinner at The Riverhut
                </li>
                <li>Day 2 – 30m scenic helicopter tour</li>
                <li>$500.00 NZD spending money</li>
              </ol>
              <li>
                There are five (x5) secondary prizes of a Deadly Ponies bags
                valued at 1,299 to be won
              </li>
              <li>
                All travel is subject to availability and may be dependent on
                select seat class with airlines or specific room category
                availability with an accommodation partner. Travel is valid 18
                months from when winner is drawn. The prize cannot be redeemed
                during the blackout periods which include all New Zealand school
                & Public Holidays, weekends and all other special event periods.
                All bookings must be made a minimum of 45 days in advance of
                requested departure dates. All bookings and documentation
                regarding the prize must be made via Free-2-Travel Holidays Pty
                Ltd or their agents. Redeeming the prize is conditional on
                acceptance of the terms and conditions of travel as detailed by
                Free-2-Travel Holidays Pty Ltd and the airline carriers in
                accordance with normal travel practices. No portion of the prize
                is redeemable for cash. In the event the winner is under the age
                of 18 years, they must be accompanied by a parent/guardian.
                Winner is responsible for any amendments fees issued by airlines
                or suppliers once booking is confirmed and ticketed. Winner is
                responsible for any additional taxes and/or resort fees
                associated with the chosen accommodation, unless otherwise
                stated. It is the Winners responsibility to organise transport
                to/from the airport departure/return point. Prize-winner and
                travel companions must have valid travel insurance for their
                period of travel.
              </li>

              <li>Deadly Ponies is in no way affiliated with this promotion</li>
              <li>
                Prizes are not transferable and are not redeemable for cash.
              </li>

              <li>
                In the event that any part of the Prize in this promotion
                becomes unavailable for reasons beyond the Promoter's control,
                the Promoter may substitute a Prize of equal or greater value.
              </li>
              <li>
                The Promoter reserves the right to request verification of age,
                proof of purchase, identity, residential address and any other
                information from entrants relevant to entry into or
                participation in this Promotion. Verification is at the
                discretion of the Promoter, whose decision is final. The
                Promoter reserves the right to disqualify any individual who
                provides false information, fails to provide information,
                conspires with others to gain an unfair advantage or who is
                otherwise involved in any way in manipulating, interfering or
                tampering with this promotion.
              </li>
              <li>
                As part of the Promoter’s commitment to being a responsible
                producer and marketer of alcoholic beverages, it is part of the
                Promoter’s policy not to award major prizes to people who have
                prior criminal convictions, particularly (but not limited to)
                where those convictions involve anti-social or violent behaviour
                or drink-driving.
              </li>

              <li>
                As a condition of accepting the Prize, the Winner(s) and any
                other person/s sharing the prize with the winner may be required
                to sign further legal documentation as and in the form
                reasonably required by the Promoter in its absolute discretion,
                including but not limited to a legal release and indemnity form
                and documentation required to conduct a Criminal Record Check.
              </li>
              <li>
                If a Winner cannot be notified (including failure to respond to
                a notification within 5 working days of the Prize draw), or
                cannot be verified (including where a winners age or place of
                residence renders an entrant ineligible to receive the Prize),
                or does not agree to and sign any further legal documentation
                required by the Promoter in accordance with relevant clauses
                above, has a criminal record, or is found to have otherwise
                breached any of these Terms and Conditions, his/her selection as
                the prize winner may be forfeited and an alternative winner may
                be selected at the Promoter’s sole discretion. No compensation
                will be awarded to the forfeiter.
              </li>
              <li>
                All decisions of the Promoter (including selection of Prize
                winners) are final and binding, no correspondence will be
                entered into.
              </li>
              <li>
                The Promoter accepts no responsibility for late, lost or
                misdirected entries. Entries will be deemed to be void if
                stolen, forged, mutilated or tampered with in any way.
              </li>

              <li>
                The Promoter and its related bodies corporate shall not be
                liable for any loss, damage or injury suffered or sustained
                (including but not limited to direct or consequential loss or
                loss arising from negligence) arising directly or indirectly out
                of or in connection with the Promotion or Prize.
              </li>
              <li>
                The Prize is subject to the terms and conditions of the relevant
                third party supplier and will be included as part of the prize
                being issued. The Promoter and its related bodies corporate are
                not responsible or liable for any direct or indirect loss
                suffered by any entrant as a direct or indirect result of the
                conduct of the third parties involved in Prize offerings for
                this Promotion.
              </li>
              <li>
                Details from all entries will be collected and used for the
                purposes of conducting this Promotion (which may include
                disclosure to third parties for the purpose of processing and
                conducting the Promotion) and for publicity purposes surrounding
                this Promotion. By entering this Promotion entrants consent to
                the use of their information as described. Entrants may access
                and/or change their data by contacting
                lioncompetitionsnz@lionco.com. The Promoter's privacy policy is
                available by{' '}
                <a href='https://lionco.com/legal/privacy-policy/'>
                  https://lionco.com/legal/privacy-policy.
                </a>
              </li>
              <li>
                The Promoter is Lion NZ Limited, 136 Fanshawe Street, Auckland
                CBD 1010.
              </li>
              <li>
                For any queries on this promotion please contact{' '}
                <a href='mailto:lioncompetitionsnz@lionco.com'>
                  lioncompetitionsnz@lionco.com
                </a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TermsPopup;

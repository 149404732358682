import React from 'react';
import { AnimatePresence } from 'framer-motion';

import Cross from '@svg/cross.svg';

const Confirmation = ({ handleConfirmationClose }) => {
  return (
    <AnimatePresence>
      <div className='bg-black w-full h-screen relative   text-center flex items-center overflow-hidden'>
        <div
          //   whileHover={{
          //     scale: 1.2,
          //   }}
          onClick={handleConfirmationClose}
          className='absolute top-5 right-1/2 translate translate-x-1/2 lg:right-20 cursor-pointer z-50  rounded-full h-16 w-16 '
        >
          <Cross className='w-[29px] lg:w-[40px] absolute top-1/2 left-1/2  -translate-y-1/2 -translate-x-1/2' />
        </div>
        <div className='w-full h-auto px-5'>
          <div className=' lg:mb-5'>
            <h2 className='text-[48px] lg:text-[80px] uppercase  font-cenzo-bold text-red'>
              Good Luck!
            </h2>
            <p className='text-[20px] sm:text-[25px] lg:text-[40px] font-cenzo-regular uppercase'>
              You’re in the draw to win.
              <br />
              We'll be in touch if you're a lucky winner.
            </p>
          </div>
        </div>
      </div>
    </AnimatePresence>
  );
};

export default Confirmation;

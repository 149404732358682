import React from 'react';

const End = () => {
  return (
    <div className='text-[36px] font-cenzo-bold text-center max-w-[646px] mx-auto '>
      <p>
        Sorry, unfortunately the promotion has now finished.
        <br />
        <br />
        Keep an eye out for future promotions.
      </p>
    </div>
  );
};

export default End;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';

const Triangle = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1, ease: 'easeInOut' }}
      className='z-20 w-[200%] lg:w-[90vw] max-w-[1224px]  absolute top-0 left-1/2 -translate-x-1/2 flex justify-center mt-8'
    >
      <StaticImage width={2448} height={1640} src='../../images/triangle.png' />
    </motion.div>
  );
};

export default Triangle;
